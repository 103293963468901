<template>
  <div v-if="article">
    <section class="section is-small">
      <div class="container">
        <p class="title">{{ article.Title }}</p>
      </div>
    </section>

    <section v-if="article.Short.length > 0" class="section is-small">
      <div class="container">
        <p>{{ article.Short }}</p>
      </div>
    </section>

    <section v-if="article.Text.length > 0" class="section is-small">
      <div class="container">
        <p v-html="article.Text" class="content"></p>
      </div>
    </section>

    <section v-if="article.Links.length > 0" class="section is-small">
      <div class="container">
        <ul>
          <li v-for="(link, index) in article.Links" :key="index">
            <a v-if="link.LinkType === 'ExternalUrl'">{{
              link.Text.length > 0 ? link.Text : link.Link
            }}</a>

            <div v-if="link.LinkType === 'YoutubeVideo'">
              <span v-if="link.Text.length > 0" class="has-text-weight-bold">{{
                link.Text
              }}</span>
              <iframe width="420" height="315" :src="link.Link"></iframe>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section v-if="article.EnableComments" class="section is-small">
      <div class="container">
        <div>
          <textarea class="textarea has-margin-bottom" v-model="commentText" />
          <!-- <a @click="cancelShowCommentInput" class="button has-margin-right"
            >Cancel</a
          > -->
          <a
            @click="sendComment"
            class="button is-primary"
            :class="{ 'is-loading': isSending }"
            >Send</a
          >
        </div>

        <comment-item
          v-for="comment in filterComments(0)"
          :key="comment.Id"
          :commentObj="comment"
          :comments="article.Comments"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import articleProvider from '@/providers/article'
import commentProvider from '@/providers/comment'
import logProvider from '@/providers/log'

export default {
  components: {},

  props: {},

  data() {
    return {
      articleId: Number(this.$route.params.articleId),
      commentText: '',
      isSending: false
    }
  },

  computed: {
    ...mapState('articleStore', ['article'])
  },

  created() {
    if (!this.article) {
      this.getArticleData()
    }

    this.logView()
  },

  methods: {
    ...mapMutations('articleStore', ['setArticle']),

    logView() {
      logProvider.methods.saveItemView(1, this.articleId)
    },

    getArticleData() {
      let self = this

      articleProvider.methods
        .getArticle(this.articleId)
        .then(response => {
          if (response.status === 200) {
            self.setArticle(response.data)
          }
        })
        .catch(e => {})
        .finally(() => {})
    },

    filterComments(parentId) {
      return this.article.Comments.filter(c => c.ParentId === parentId)
    },

    sendComment() {
      if (!this.isSending) {
        this.isSending = true

        commentProvider.methods
          .addComment('article', this.article.Id, this.commentText)
          .then(response => {
            this.article.Comments.push(response.data)
          })
          .then(() => {
            this.isSending = false
          })
      }

      this.commentText = ''
    }
  }
}
</script>

<style></style>
